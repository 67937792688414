<template>
        <v-card :to="'/activity/'+post.id"
          class="mb-3 mx-auto"
          max-width="300" elevation="0"
                height="350"
        >
          <v-img
            :src="postimage.uploaddir+'270_'+postimage.postimage"
            max-height="300"
            gradient="to top, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            :lazy-src="require('@/assets/images/holder.jpg')"
          >
            <v-card-actions class="pa-1">
              <v-list-item
                class="grow pa-1"
                dense
              >
                <v-list-item-avatar color="grey darken-3">
                  <v-img
                    v-if="post.user.avatar"
                    contain
                    class="elevation-6"
                    alt=""
                    :src="require('@/assets/images/avatars/'+post.user.avatar)"
                  ></v-img>
                  <v-img
                    v-else
                    contain
                    class="elevation-6"
                    alt=""
                    :src="require('@/assets/images/avatars/logo.png')"
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="">
                    <router-link :to="'/profile/'+post.user.id"><b>{{ post.user.username }}</b>
                    </router-link>
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ post.creationdate }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-row
                  align="center"
                  justify="end"
                >

                  <template v-if="user.id != post.user.id">
                    <follow-toggle-component
                      v-if="post.user.following !== 1"
                      :status="post.user.following"
                      :user_id="parseInt(post.user.id)"
                      :current_user_id="parseInt(user.id)"
                      @initlogin="()=>$emit('initlogin')"
                      @following="following"
                    ></follow-toggle-component>

                  </template>
                  <!--            <v-btn v-if="post.postimages.length > 1"
                                     class="mr-3"
                                     icon title="Ouvrir avec le lecteur d'image"
                                     @click="$emit('imageViewer', post)"
                              >
                                <v-icon class="mr-1">
                                  {{ icons.mdiCollapseAllOutline }}
                                </v-icon>
                              </v-btn>-->

                  <v-menu
                    bottom
                    left
                    offset-x
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon class="mr-1">
                          {{ icons.mdiDotsVertical }}
                        </v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item :to="'/activity/'+post.id">
                        <v-list-item-title>Aller au post</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="loader.dialog = true">
                        <v-list-item-title>Partager</v-list-item-title>
                      </v-list-item>
                      <template v-if="post.user.id == user.id">
                        <v-list-item v-if="post.category.slug == '4'"
                                     @click="$emit('replyPost', post, index)"
                        >
                          <v-list-item-title> Ajouter un episode</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          color="error"
                          @click="$emit('editPost', post, index)"
                        >
                          <v-list-item-title>Modifier ma publication</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          color="error"
                          @click="deletePost"
                        >
                          <v-list-item-title>Supprimer ma publication</v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </v-row>
              </v-list-item>
            </v-card-actions>
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0 "
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-row class="text-center">
                <v-col class="text--primary">
                  <v-icon>{{ icons.mdiHeartOutline }}</v-icon>
                  {{post.nbkola}}
                </v-col>
                <v-col v-if="post.nbcomment.length" class="text--primary">
                  <v-icon>{{ icons.mdiCommentOutline }}</v-icon>
                  {{post.nbcomment}}
                </v-col>
              </v-row>
        </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiDotsVertical,
  mdiCommentOutline,
  mdiComment,
  mdiShareVariant,
  mdiHeartOutline,
  mdiHeart,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import { getVuetify } from '@/plugins/utils'
import FollowToggleComponent from "@/components/FollowToggleComponent.vue";

export default {
  components: {FollowToggleComponent},
  props: {
    postimage: Object,
    postItem: Object,
    user: Object,
    limit: Number,
    index: Number,
    loadreply: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { emit }) {
    const $vuetify = getVuetify()
    const loader = ref({
      loading: true,
      like: false,
    })

    const post = ref(JSON.parse(JSON.stringify(props.postItem)))

    const toggleKola = () => {
      if (props.user.id) {
        loader.value.like = true
        Drequest.toggleKola('post', post.value.id, post.value.liked)
          .post(response => {
            console.log(response)
            loader.value.like = false
            post.value.liked = !post.value.liked
            post.value.nbkola = response.nbkola
          })
      } else {
        alert('Vous devez etre connecte pour liker')
      }
    }
    const copytoclipboard = () => {
      navigator.clipboard.writeText(`https://reader.3ag-edition.com/activity/${post.value.id}`)
      alert('Le lien de la publication a bien ete copier dans le presse papier!')
    }
    const following = () => {
      alert('Le lien de la publication a bien ete copier dans le presse papier!')
    }
    const deletePost = () => {
      if (!confirm('Cette publication va etre supprime.\n\n Vous confirmez cette action?')) {
        return 0
      }

      Drequest.api(`post.delete?id=${post.value.id}`)
        .get(response => {
          console.log(response)
          alert(response.detail)
          emit('deleted', props.index)
        })
        .fail(e => {
          console.log(e)
          alert('Oops! Il y a un probleme de connexion.')
        })
    }

    return {
      toggleKola,
      copytoclipboard,
      deletePost,
      following,

      post,
      loader,

      icons: {
        mdiCommentOutline,
        mdiDotsVertical,
        mdiComment,
        mdiShareVariant,
        mdiHeart,
        mdiHeartOutline,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
