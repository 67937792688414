<template>
  <div id="home" class="mb-15">

      <banner></banner>

<!--    <contest-chapters  v-if="contest.contest.id" :contest="contest.contest" ></contest-chapters>-->

<!--    <home-new-catalog></home-new-catalog>-->

    <home-new-chapters
      :title="'Dernieres Sorties'"
      :options="'chapter'">
    </home-new-chapters>

    <v-card
      v-if="contest.advertising.id"
      max-width="800"
      class="mt-6 mb-6 ma-auto"
    >
      <v-img
        :src="contest.advertising.image"
        class="white&#45;&#45;text align-end"
        gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,.6)"
        height="200px"
      >
        <v-card-title class="text-lg-h1 text-sm-h3">
          <v-spacer></v-spacer>
          <v-btn
            :to="'contest/'+contest.contest.id"
            color="primary"
          >
            En savoir plus
          </v-btn>
        </v-card-title>
      </v-img>
    </v-card>

    <home-new-posts
      :user="user"
      :user_id="user.id"
      @login="()=>dialog.authentication = true"
    ></home-new-posts>

    <home-new-chapters
      :title="'One Shot Communaute'"
      :options="'oneshot'"
    ></home-new-chapters>

    <v-card elevation="0" class="mb-6">
      <v-card-title>
        Derniers Commentaires
        <v-spacer></v-spacer>

      </v-card-title>
      <v-card elevation="0" v-if="lastcomments.length == 0"  v-for="n in 3"
               :color="`grey darken-2 `"
               :key="n"
      >
        <v-skeleton-loader
          class="mx-auto"
          :height="100"
          type="list-item-avatar-two-line"
        ></v-skeleton-loader>
      </v-card>
      <v-card-text>
        <v-list dense>
          <template v-for="(item, index) in lastcomments">
            <v-divider
              :key="index"
            ></v-divider>

            <v-list-item
              :key="item.title"
              dense
              :to="'/scan/'+item.cb_nameseo+'/'+item.c_id"
            >
              <v-img v-if="item.c_cover"
                class="mr-3"
                max-width="50"
                :src="item.cover"
                :lazy-src="require('@/assets/images/holder.jpg')"></v-img>
              <img v-else
                   class="mr-3"
                   width="50"
                   :src="require('@/assets/images/holder.jpg')"
              >
              <v-list-item-content>
                <v-list-item-title class="title-inline">
                   @{{ item.username }}
                  <v-spacer></v-spacer>
                </v-list-item-title>
                {{item.comment}}

                <v-list-item-subtitle>
                  <b>#{{ item.cb_name }}</b> {{ item.c_title }}
                  <!-- - <v-icon small>
                  {{ icons.mdiEyeOutline }}
                </v-icon> {{ item.nbviews }}-->
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ item.created_at }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
              </v-list-item-action>

            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
    </v-card>

    <v-card elevation="0">
      <v-card-title>
        Les BDs les plus vues
        <v-spacer></v-spacer>

      </v-card-title>
      <v-card elevation="0" v-if="lastcomments.length == 0"  v-for="n in 3"
               :color="`grey darken-2 `"
               :key="n"
      >
        <v-skeleton-loader
          class="mx-auto"
          :height="100"
          type="list-item-avatar-two-line"
        ></v-skeleton-loader>
      </v-card>
      <v-card-text>
        <v-list >
          <template v-for="(item, index) in comicbook_topviews">
            <v-divider
              :key="index"
            ></v-divider>

            <v-list-item
              :key="item.name"

              :to="'/comicbook/'+item.nameseo+'/'+item.id"
            >
              <v-avatar>
                <v-img
                  class="mr-3"
                  max-width="50"
                  :src="item.banner"
                  :lazy-src="require('@/assets/images/holder.jpg')"></v-img>
              </v-avatar>

              <v-list-item-content>
                <v-list-item-title class="title-inline">
                   {{ item.name }}
                  <v-spacer></v-spacer>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <v-icon small>
                  {{ icons.mdiEyeOutline }}
                </v-icon> {{ item.nbviews }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
              </v-list-item-action>

            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
    </v-card>

    <home-new-gallery></home-new-gallery>

  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiCartOutline,
  mdiClose,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import { getVuetify } from '@/plugins/utils'
import Banner from '@/components/Banner'
import HomeNewChapters from '@/components/HomeNewChapters.vue'
import HomeNewCatalog from '@/components/HomeNewCatalog.vue'
import HomeNewGallery from '@/components/HomeNewGallery.vue'
import ContestWinner from '@/components/ContestWinner.vue'
import ContestChapters from '@/components/ContestChapters.vue'
import PostComponent from '@/components/PostComponent.vue'
import HomeNewPosts from "@/components/HomeNewPosts.vue";

export default {
  components: {HomeNewPosts, PostComponent, HomeNewGallery, HomeNewCatalog, HomeNewChapters, Banner, ContestWinner, ContestChapters },
  setup() {
    const $vuetify = getVuetify()
    const user = Drequest.getUser()
    const isPasswordVisible = ref(false)

    const tab = ref('one')
    const items = ref([])
    const challenges = ref([])
    const lastcontest = ref([])
    const lastimages = ref([])
    const lastcomments = ref([])
    const comicbook_topviews = ref([])
    const product = ref({ shop: {} })
    const loader = ref({
      news: true,
      oneshotnews: true,
      products: true,
      reloadprod: false,
      reloadchapter: false,
      oneshotreloadchapter: false,
    })
    const mcs = ref([])
    const advertising = ref({})
    const socialLink = [
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]
    const columns = computed(() => {
      if ($vuetify.breakpoint.sm) {
        return 120
      }
      if ($vuetify.breakpoint.xs) {
        return 108
      }

      return 210
    })
    const slider_height = computed(() => {
      if ($vuetify.breakpoint.xl) {
        return {
          news: 270,
          product: 270,
        }
      }

      if ($vuetify.breakpoint.lg) {
        return {
          news: 270,
          product: 270,
        }
      }

      if ($vuetify.breakpoint.md) {
        return {
          news: 210,
          product: 210,
        }
      }
      if ($vuetify.breakpoint.sm) {
        return {
          news: 180,
          product: 180,
        }
      }
      if ($vuetify.breakpoint.xs) {
        return {
          news: 150,
          product: 150,
        }
      }

      return {
        news: 270,
        product: 270,
      }
    })
    const showarrow = computed(() => {
      if ($vuetify.breakpoint.sm || $vuetify.breakpoint.xs) {
        return false
      }

      return true
    })

    const postll = ref({})
    // const user = Drequest.getUser()
    const contest = ref({advertising:{}, lastcontest:{}, challenges:[], contest:{}})
    const init = (next) => {
      loader.value.reloadchapter = false
      loader.value.reloadprod = false

      Drequest.api('contest.homepage')
        .get(response => {
          console.log(response)
           contest.value = response
          /*
          localStorage.set('advertising', JSON.stringify(contest.value.advertising))
          localStorage.set('contest', JSON.stringify(contest.value.contest))
          */
          challenges.value = response.challenges
          lastcontest.value = response.lastcontest
          lastimages.value = response.lastimages
          lastcomments.value = response.lastcomments
          comicbook_topviews.value = response.comicbook_topviews
        })

    }
    init(1)

    return {
      init,
      user,
      comicbook_topviews,
      lastcomments,
      isPasswordVisible,
      contest,
      loader,
      socialLink,
      items,
      columns,
      mcs,
      showarrow,
      slider_height,
      tab,

      icons: {
        mdiCartOutline,
        mdiClose,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
#home .v-tabs-bar {
  border-radius: inherit;
  height: inherit !important;
}
.v-application.theme--light #home .v-tabs:not(.v-tabs--vertical) {
  box-shadow: none !important;
}
@media (max-width: 450px) {
  .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
    display: none !important;
    visibility: hidden;
  }
}
</style>
