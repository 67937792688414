<template>
  <div>

    <v-card
      elevation="0"
      class=""
    >
      <v-card-title class="pa-2">
        Communaute
        <v-spacer></v-spacer>
        <v-btn icon
          to="/social"
          small
          color="primary"
        >
          <v-icon>{{icons.mdiArrowRight}}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-actions
        v-if="loader.reload"
        class="text-center"
      >
        <v-btn
          class="ma-auto"
          color="primary"
          @click="init()"
        >
          Recharger
        </v-btn>
      </v-card-actions>
      <template v-else>
        <v-sheet
          v-if="loader.loading"
          class="mx-auto"
        >
          <v-slide-group
            multiple
            :show-arrows="showarrow"
          >
            <v-slide-item
              v-for="n in 9"
              :key="'lnew'+n"
              v-slot="{ }"
            >
              <v-card
                :color="`grey darken-2 `"
                class="pa-3"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  :height="slider_height.news"
                  :width="columns"
                  type="card"
                ></v-skeleton-loader>
              </v-card>
            </v-slide-item>

            <!--          <v-slide-item v-else
                                    v-for="(item, index) in newchapters"
                                    :key="'new-'+index"
                                    v-slot="{ }"
                      >
                      </v-slide-item>-->
          </v-slide-group>
        </v-sheet>
        <v-sheet
          v-else
          class="mx-auto "
        >
          <v-slide-group
            multiple
            :show-arrows="showarrow"
          >
            <v-slide-item
              v-for="(post, index) in postll.listEntity"
              :key="'new-'+index"
              v-slot="{ }"
            >
              <post-summary-component class=" social-post pa-1"
                :key="post.id"
                :index="index"
                :post-item="post"
                :postimage="post.postimages[0]"
                :user="user"
                :limit="0"
                                      @login="()=>$emit('login')"
                                      @editPost="(post, index)=>$emit('editPost', post, index)"
                                      @replyPost="(post, index)=>$emit('replyPost', post, index)"
                                      @deleted="(index)=>$emit('deleted', index)"
                                      @imageViewer="(item) => $emit('imageViewer', item)"
                                      @initlogin="()=>$emit('initlogin')"
              ></post-summary-component>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </template>
    </v-card>

  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiArrowRight,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline, mdiCartOutline, mdiClose,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import { getVuetify } from '@/plugins/utils'
import Icons from '@/views/icons/Icons.vue'
import PostComponent from '@/components/PostComponent.vue'
import PostMinifiedComponent from "@/components/PostMinifiedComponent.vue";
import PostSummaryComponent from "@/components/PostSummaryComponent.vue";

export default {
  components: {PostSummaryComponent, PostMinifiedComponent, PostComponent, Icons },
  props: {
    user: {
      type: Object,
      default: null,
    },
    user_id: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const $vuetify = getVuetify()
    const postll = ref({})
    const next = ref(1)

    const loader = ref({
      loading: true,
      oneshotnews: true,
      products: true,
      reloadprod: false,
      reloadchapter: false,
      oneshotreloadchapter: false,
    })
    const mcs = ref([])
    const socialLink = [
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]
    const columns = computed(() => {
      if ($vuetify.breakpoint.sm) {
        return 120
      }
      if ($vuetify.breakpoint.xs) {
        return 108
      }

      return 210
    })

    const showarrow = computed(() => {
      if ($vuetify.breakpoint.sm || $vuetify.breakpoint.xs) {
        return false
      }

      return true
    })

    // const user = Drequest.getUser()

    const init = () => {

      loader.value.loading = true
      loader.value.retry = false
      Drequest.api('lazyloading.post?dfilters=on&dsort=id desc')
        .param({
          per_page: 6,
          next : next.value,
          ...loader.value.options,
          user_id: props.user.id,
          'status._key:neq': 'draft'
        })
        .get(response => {
          console.log(response)

          loader.value.loading = false
          if (next.value >= 2) {
            postll.value.listEntity = [...postll.value.listEntity, ...response.listEntity]
            postll.value.next = response.next

          } else {
            postll.value = response
          }

        })
        .fail(e => {
          loader.value.loading = false
          loader.value.retry = true
          console.log(e)
        })
    }
    init()
    const slider_height = computed(() => {
      if ($vuetify.breakpoint.xl) {
        return {
          news: 270,
          product: 270,
        }
      }

      if ($vuetify.breakpoint.lg) {
        return {
          news: 270,
          product: 270,
        }
      }

      if ($vuetify.breakpoint.md) {
        return {
          news: 210,
          product: 210,
        }
      }
      if ($vuetify.breakpoint.sm) {
        return {
          news: 180,
          product: 180,
        }
      }
      if ($vuetify.breakpoint.xs) {
        return {
          news: 150,
          product: 150,
        }
      }

      return {
        news: 270,
        product: 270,
      }
    })

    return {
      init,

      postll,
      loader,
      socialLink,
      columns,
      mcs,
      showarrow,
      slider_height,

      icons: {
        mdiCartOutline,
        mdiClose,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiArrowRight
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
